/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 12, 2017 */



@font-face {
    font-family: 'sf_compact_display';
    src: url('./sf-compact-display-semibold-webfont.woff2') format('woff2'),
         url('./sf-compact-display-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}




@font-face {
    font-family: 'sf_compact_display';
    src: url('./sf-compact-display-ultralight-webfont.woff2') format('woff2'),
         url('./sf-compact-display-ultralight-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;

}




@font-face {
    font-family: 'sf_compact_display';
    src: url('./sf-compact-display-bold-webfont.woff2') format('woff2'),
         url('./sf-compact-display-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'sf_compact_display';
    src: url('./sf-compact-display-light-webfont.woff2') format('woff2'),
         url('./sf-compact-display-light-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;

}




@font-face {
    font-family: 'sf_compact_display';
    src: url('./sf-compact-display-regular-webfont.woff2') format('woff2'),
         url('./sf-compact-display-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'sf_compact_display';
    src: url('./sf-compact-display-medium-webfont.woff2') format('woff2'),
         url('./sf-compact-display-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}




@font-face {
    font-family: 'Dosis';
    src: url('./Dosis-Regular.woff') format('woff2'),
         url('./Dosis-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}




@font-face {
    font-family: 'Dosis';
    src: url('./Dosis-Bold.woff') format('woff2'),
         url('./Dosis-Bold.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}